
<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'Point',

    metaInfo: { title: 'Fitsea | Website Resmi Fitsea' },

    extends: View,

    mixins: [
      LoadSections([
        'point',
        // 'theme-features',
        // 'features',
        // 'contact-us',
        // 'affiliates',
        // 'social-media',
        // 'keep-in-touch',
        // 'newsletter',
        // 'pro-features',
        // 'info',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'home',
      },
    },
  }
</script>
